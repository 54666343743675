import type { SuggestionSelectedEventData } from 'react-autosuggest'
import { suggestAddress } from '@/api/kubik/dadata'
import { isRespondedOk } from '@/api/request'
import { ECommonApiUrl } from '@/api/urls'
import { getUrlWithQuery } from '@/utils/url'
import type { TOnSuggestionsFetchRequested } from '../Autosuggest/interfaces'
import { isCitySuggestionFormAlfa, isCitySuggestionFormDadata } from './typeguard'
import type { IAlfaSuggestionCity, IDadataSuggestionCity } from './types'

export const onSuggestionSelectedWithCode = async ({
  suggestion,
}: SuggestionSelectedEventData<IDadataSuggestionCity | IAlfaSuggestionCity>) => {
  const { fiasCode } = suggestion

  return fiasCode
}

export const onSuggestionSelectedWithName = async ({
  suggestion,
}: SuggestionSelectedEventData<IDadataSuggestionCity | IAlfaSuggestionCity>) => {
  if (isCitySuggestionFormDadata(suggestion)) {
    const { city, region } = suggestion

    return `${city}, ${region}`
  }

  return null
}

export const onSuggestionSelectedWithNameAndRegionKladrId = async ({
  suggestion,
}: SuggestionSelectedEventData<IDadataSuggestionCity | IAlfaSuggestionCity>) => {
  if (isCitySuggestionFormDadata(suggestion)) {
    const { city, region, regionKladrId } = suggestion

    return { city, region, regionKladrId }
  }

  return null
}

export const onSuggestionSelectedWithAll = async ({
  suggestion,
}: SuggestionSelectedEventData<IDadataSuggestionCity | IAlfaSuggestionCity>) => {
  if (isCitySuggestionFormDadata(suggestion)) {
    const { city, region, fiasCode } = suggestion

    return { city, region, fiasCode }
  }

  return null
}

export const onSuggestionsFetchRequestedFromDadata: TOnSuggestionsFetchRequested<IDadataSuggestionCity> = async ({ value }) => {
  const { suggestions } = await suggestAddress({
    query: value,
    from_bound: { value: 'city' },
    to_bound: { value: 'settlement' },
    restrict_value: true,
    count: 20,
  })

  if (!suggestions) return Promise.reject()

  const formattedSuggestion = suggestions.filter(suggestion => suggestion.data?.city_district === null)

  const suggests: IDadataSuggestionCity[] = formattedSuggestion.map(suggestion => {
    return {
      fiasCode: suggestion.data?.fias_id || '',
      city: suggestion.value || '',
      region: suggestion.data?.region_with_type || '',
      regionKladrId: suggestion.data?.region_kladr_id || '',
    }
  })

  return Promise.resolve(suggests)
}

export const onSuggestionsFetchRequestedFromAlfa: TOnSuggestionsFetchRequested<IAlfaSuggestionCity> = async ({ value }) => {
  const url = getUrlWithQuery(ECommonApiUrl.cities, { searchField: value })
  const response = await fetch(url.toString(), { method: 'GET' })
  const isSuccess = isRespondedOk<IAlfaSuggestionCity[]>({ response })
  if (!isSuccess) return []

  const { data } = await response.json()

  return data.cities
}

export const getSuggestionValue = (cityInfo: IAlfaSuggestionCity | IDadataSuggestionCity) => {
  if (isCitySuggestionFormAlfa(cityInfo)) {
    const { region, name } = cityInfo

    return `${region.name}, ${name}`
  }

  return `${cityInfo.city}`
}
